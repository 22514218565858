import { render, staticRenderFns } from "./loading-overlay.vue?vue&type=template&id=0312ed64&scoped=true"
import script from "./loading-overlay.vue?vue&type=script&lang=js"
export * from "./loading-overlay.vue?vue&type=script&lang=js"
import style0 from "./loading-overlay.vue?vue&type=style&index=0&id=0312ed64&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0312ed64",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src3295261676/src/web/components/loading-spinner.vue').default})
